import React, { useEffect, useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { FaDiscord } from "react-icons/fa";
// import ToggleSwitch from '../../elements/toggle/ToggleSwitch';
// import 'rsuite/dist/styles/rsuite-default.css';
import "rsuite/dist/rsuite.css";
import { Toggle } from 'rsuite';
import {
  Connection,
  Keypair,
  SystemProgram,
  LAMPORTS_PER_SOL,
  Transaction,
  sendAndConfirmTransaction,
  TransactionSignature,
} from "@solana/web3.js";

import axios from "axios";
import * as buffer from "buffer";
window.Buffer = buffer.Buffer;

const apiUrl = process.env.REACT_APP_BACKEND_API;
const discordAuth = process.env.REACT_APP_DISCORD_AUTH_URL;
require('./output.css');

const BannerVerify = () => {


  const { connected, signMessage, publicKey, sendTransaction } = useWallet();
  const [status, setStatus] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [userGuilds, setUserGuilds] = useState(null);
  const [data, setData] = useState(null);
  let { connection } = useConnection();

  useEffect(() => {
    if (!connected) setStatus(null);
  }, [connected]);

  useEffect(() => {
    if (!userInfo) setUserInfo(null);
  }, [userInfo]);

  useEffect(() => {
    if (!userGuilds) setUserGuilds(null);
  }, [userGuilds]);

  // useEffect(() => {
  //   if (!data) setData(null);
  //   setData(data);
  // }, [data]);

  useEffect(() => {
    validateDiscordUser();
  }, []);

  const validateDiscordUser = async () => {

    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];
    //console.log(accessToken);
    //let userInfo = {};
    if (accessToken && !userInfo) {
      // console.log('callind discord api');

      axios.get('https://discord.com/api/users/@me', {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        }
      })
        .then(response => {
          // console.log(response);
          setUserInfo(response);
        })
        .catch(console.error);

      //get users guild
      axios.get('https://discord.com/api/users/@me/guilds', {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        }
      })
        .then(response => {
          // console.log(response);
          setUserGuilds(response);
        })
        .catch(console.error);
    }

  }



  const toHexString = (buffer) => {
    return buffer.reduce(
      (str, byte) => str + byte.toString(16).padStart(2, "0"),
      ""
    );
  };

  // const data = {
  //   pubKey:publicKey,
  // };
  const getMessage = async () => {
    setData(null)
    const result = await axios({
      method: "post",
      url: apiUrl + "/GetMessage?code=9_gfrvSARkeoDeg9PunYAlcxP6Yi-bWkjAQuSjppTmTfAzFuacjycg%3D%3D",
      data: {
        publicKey: publicKey,
      },
    });
    // console.log(`data from api ${result.data}`)
    setData({
      data: result.data
    });

    return result;
  }

  const sign = async () => {
    if (!connected) return;
    const dataNew = await getMessage();
    //console.log(data)
    //console.log(dataNew)
    // if (!data) { return 'sorry an error happened getting message.' }
    const nonceEncoded = new TextEncoder().encode(btoa(JSON.stringify(dataNew.data)));
    const signed = await signMessage(nonceEncoded);
    axios({
      method: "post",
      url: apiUrl + "/Sign?code=iJ8y-GuZ5KvCj3Lk50cjs3jXe8VFYg-IE_OsWzZR4siTAzFul67lFQ%3D%3D",
      data: {
        signature: toHexString(signed),
        publicKey: publicKey,
        data: dataNew,
        userInfo: userInfo.data,
        userGuilds: userGuilds.data
      },
    })
      .then((res) => {
        setStatus({
          status: "success",
          message: res.data,
        });
      })
      .catch((err) => {
        setStatus({
          status: "error",
          message: err.response.data,
        });
      });
  };

  const ledgerSignin = async () => {
    if (!connected) return;
    try {
      const dataNew = await getMessage();
      const trx = await ledgerTransaction();
      axios({
        method: "post",
        url: apiUrl + "/Sign?code=iJ8y-GuZ5KvCj3Lk50cjs3jXe8VFYg-IE_OsWzZR4siTAzFul67lFQ%3D%3D",
        data: {
          signature: trx,
          publicKey: publicKey,
          data: dataNew,
          userInfo: userInfo.data,
          userGuilds: userGuilds.data,
          isLedger: true
        },
      })
        .then((res) => {
          setStatus({
            status: "success",
            message: res.data,
          });
        })
        .catch((err) => {
          setStatus({
            status: "error",
            message: err.response.data,
          });
        });
    } catch (error) {
      setStatus({
        status: "error",
        message: 'Sorry an error happened during this operation.',
      });
    }
  }

  const ledgerTransaction = async () => {
    // const fromKeypair = Keypair.generate();
    // const toKeypair = Keypair.generate();
    const rpcUrl = process.env.REACT_APP_RPC_URL

    connection = new Connection(
      rpcUrl,
      "confirmed"
    );

    // const airdropSignature = await connection.requestAirdrop(
    //   fromKeypair.publicKey,
    //   LAMPORTS_PER_SOL
    // );

    // await connection.confirmTransaction(airdropSignature);

    const lamportsToSend = 1_000_0;
    let signature = '';
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: publicKey,
        lamports: lamportsToSend,
      })
    );
    // console.log(connection);
    signature = await sendTransaction(transaction, connection);

    await connection.confirmTransaction(signature, 'confirmed');
    // const tx = await sendAndConfirmTransaction(connection, transferTransaction, [
    //   fromKeypair,
    // ]);

    //console.log(signature);
    return signature;

  }


  // const [searchParams, setSearchParams] = useSearchParams();
  // console.log(searchParams.entries());
  // const discordToken = searchParams.get('access_token')
  // // const tokenType = searchParams.get('token_type')
  // // const f = searchParams.get('f')
  // console.log(discordToken);
  // const updateOrder = (sort) => {
  //   setSearchParams({ sort })
  // }
  // State for the current toggle value
  const [currentValue, setCurrentValue] = useState(0)

  return (
    <div className="banner banner-style-2">
      <div className="container-fluid">
        <div className="row align-items-left">
          <div className="col-lg-10">
            <div className="banner-content">
              <h2 className="title">Toolbox NFTs</h2>
              <h5 className="">Verify Wallet</h5>
              {userInfo ? (
                <div className="row align-items-center" >
                  <div id="name" className="col-lg-6 text-4xl mt-3 flex items-center font-medium">
                    <img src={`https://cdn.discordapp.com/avatars/${userInfo.data.id}/${userInfo.data.avatar}.jpg`} id="avatar" className="rounded-full w-12 h-12 mr-3 style=border-radius:20px" alt='' />

                    <div className=""><h5>{userInfo.data.username}#{userInfo.data.discriminator}</h5></div>
                  </div>
                  <div className="col-lg-6 mt-3 flex items-center">
                    {connected ? (
                      <>
                        <div>
                          <label className='mr-3'><h5>Ledger Wallet?  </h5></label>
                          <Toggle size="md" checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => { setCurrentValue(value) }} />

                          {status ? (
                            <div
                              className={`status ${status && status.status}`}
                              onClick={() => setStatus(null)}
                            >
                              <p className="col-lg-6 text-4xl mt-3 flex items-center ">{status && status.message}</p>
                            </div>
                          ) : (
                            <div>

                              {currentValue === true ? (
                                <><br />
                                  <h5><p>In order to validate ledger ownernship <br />you will need to send a transaction to yourself.
                                    <br />Cost: 0.000005 SOL
                                  </p></h5>
                                  <p /><br />
                                  <a className=" axil-btn btn-fill-white" href="#" onClick={ledgerSignin}> Sign Ledger Transaction</a>
                                </>
                              ) : (
                                <>
                                  <a className=" axil-btn btn-fill-white" href="#" onClick={sign}> Sign Message</a>
                                </>
                              )}
                            </div>
                          )
                          }

                          {/* {!status && <a className=" axil-btn btn-fill-white" href="#" onClick={sign}> Sign Message</a>} */}
                          {/* {!status && <a className=" axil-btn btn-fill-white" href="#" onClick={ledgerSignin}> Ledger Sign</a>} */}
                        </div>

                      </>
                    ) : (
                      <p>Connect wallet to start 🥰</p>
                    )}
                  </div>
                </div>

              ) : (
                <div>
                  <a className="axil-btn btn-fill-white" href={process.env.REACT_APP_DISCORD_AUTH_URL}><FaDiscord className='fancy-link__icon' /> Login with Discord</a>
                </div>
              )
              }
              {/* <Link to="https://discord.com/api/oauth2/authorize?client_id=1018337890884931654&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdemo%2Freact%2Fabstrak&response_type=code&scope=identify%20email%20guilds" className="axil-btn btn-fill-white btn-large"> <FaDiscord className='fancy-link__icon' /> Login with Discord</Link> */}
            </div>
            {/* <button className="axil-btn btn-fill-white btn-large" type="submit"><FaDiscord className='fancy-link__icon' /> Login with Discord</button> */}
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-18">
        <li className="shape shape-1">
          <AnimationOnScroll animateIn="slideInRight" duration={1} animateOnce={true} delay={100}>
            <img src={process.env.PUBLIC_URL + "/images/banner/Solana_Glass_04_400x400.png"} alt="Shape" />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-2">
          <AnimationOnScroll animateIn="slideInLeft" duration={1} animateOnce={true}>
            <img src={process.env.PUBLIC_URL + "/images/banner/Solana_Glass_03_300x300.png"} alt="Shape" />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-3">
          <AnimationOnScroll animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
            <img src={process.env.PUBLIC_URL + "/images/others/Size=24, Color=white.png"} alt="Shape" />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-4">
          <AnimationOnScroll animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
            <img src={process.env.PUBLIC_URL + "/images/others/Size=48, Color=color.png"} alt="Shape" />
          </AnimationOnScroll>
        </li>
        {/* <li className="shape shape-5">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Shape" />
                </AnimationOnScroll>
                </li> */}
        <li className="shape shape-6">
          <AnimationOnScroll animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
            <img src={process.env.PUBLIC_URL + "/images/others/Size=24, Color=white.png"} alt="Shape" />
          </AnimationOnScroll>
        </li>
        {/* <li className="shape shape-7">
                    <AnimationOnScroll  animateIn="slideInDown" duration={1} animateOnce={true} delay={500}>
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-26.png"} alt="Shape" />
                    </AnimationOnScroll>
                </li> */}
      </ul>
    </div>
  )
}

export default BannerVerify;