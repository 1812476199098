//import React from 'react';
import FooterVerify from '../common/footer/FooterVerify';
import HeaderVerify from '../common/header/HeaderVerify';
import SEO from '../common/SEO';
import BannerVerify from '../component/banner/BannerVerify';

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import React, { useEffect, useMemo, useState } from 'react';




// require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const VerifyWallet = () => {
  return (
    <Context>
      <Content />
    </Context>
  );
};
export default VerifyWallet;


const Context = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [

      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
    ],
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

const Content = () => {

  // const { connected, signMessage, publicKey } = useWallet();
  // const [status, setStatus] = useState(null);

  // useEffect(() => {
  //   if (!connected) setStatus(null);
  // }, [connected]);

  // const toHexString = (buffer) => {
  //   return buffer.reduce(
  //     (str, byte) => str + byte.toString(16).padStart(2, "0"),
  //     ""
  //   );
  // };

  // const data = {
  //   my_nft: "test",
  // };

  // const sign = async () => {
  //   if (!connected) return;
  //   const nonceEncoded = new TextEncoder().encode(btoa(JSON.stringify(data)));
  //   const signed = await signMessage(nonceEncoded);
  //   axios({
  //     method: "post",
  //     url: "http://localhost:5001/sign",
  //     data: {
  //       signature: toHexString(signed),
  //       publicKey: publicKey,
  //       data: data,
  //     },
  //   })
  //     .then((res) => {
  //       setStatus({
  //         status: "success",
  //         message: res.data,
  //       });
  //     })
  //     .catch((err) => {
  //       setStatus({
  //         status: "error",
  //         message: err.response.data,
  //       });
  //     });
  // };

  //   const [searchParams, setSearchParams] = useSearchParams();
  //   console.log(searchParams);
  //   const discordToken = searchParams.get('access_token')
  //   const tokenType = searchParams.get('token_type')
  //   // const f = searchParams.get('f')
  // console.log(discordToken);  
  //   const updateOrder = (sort) => {
  //     setSearchParams({ sort })
  //   }
  return (
    <>
      <SEO title="Verify Wallet" />
      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderVerify />
        <BannerVerify />
       
        {/* <div className="section section-padding">
                <div className="container">
                <SectionTitle 
                    subtitle="What We Can Do For You"
                    title="Services we can <br> help you with"
                    description=""
                    textAlignment="heading-left mb--20 mb_md--70"
                    textColor=""
                />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                    </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>

            </div> */}
        {/* <ProjectOne parentClass="bg-color-light"/>
            <CounterUpOne />
            <TestimonialOne />
            <BrandOne />
            <BlogOne />
            <CtaLayoutOne /> */}
        <FooterVerify parentClass="" />
      </main>
    </>
  )
}

// export default VerifyWallet;

